<template>
  <v-card class="pa-4" height="100%">
    <Loading :loading="isLoading"></Loading>

    <v-layout align-center>
      <v-flex shrink>
        <v-img
          aspect-ratio="1"
          width="30"
          :src="require('@/assets/icon/course/note-text.png')"
        />
      </v-flex>
      <v-flex xs10 class="pl-5">
        <div class="font-16 font-w-600">วันจัดอบรม</div>
        <div class="font-16">
          {{
            changeFormat(model.open_date_courses, "YYYY-MM-DD", "DD/MM/YYYY")
          }}
          {{ changeFormat(model.open_time_courses, "HH:mm:ss", "HH.mm") }}-{{
            changeFormat(model.end_time_courses, "HH:mm:ss", "HH.mm")
          }}
        </div>
      </v-flex>
    </v-layout>

    <v-divider class="my-3" />

    <v-layout align-center>
      <v-flex shrink>
        <v-img
          aspect-ratio="1"
          width="30"
          :src="require('@/assets/icon/course/profile-add.png')"
        />
      </v-flex>
      <v-flex xs10 class="pl-5">
        <div class="font-16 font-w-600">วันที่ปิดรับสมัคร</div>
        <div class="font-16">
          {{ changeFormat(model.end_date_recruit, "YYYY-MM-DD", "DD/MM/YYYY") }}
        </div>
      </v-flex>
    </v-layout>

    <v-divider class="my-3" />

    <v-layout align-center>
      <v-flex shrink>
        <v-img
          aspect-ratio="1"
          width="30"
          :src="require('@/assets/icon/course/user.png')"
        />
      </v-flex>
      <v-flex xs10 class="pl-5">
        <div class="font-16 font-w-600">รับจำนวนผู้เข้าร่วมหลักสูตร</div>
        <div class="font-16">
          {{ model.user_course_normal_count }}/{{ model.number_user }} คน ว่าง :
          {{ model.number_user - model.user_course_normal_count }} คน
        </div>
      </v-flex>
    </v-layout>

    <v-layout align-center class="mt-4">
      <v-flex shrink>
        <v-img
          aspect-ratio="1"
          width="30"
          :src="require('@/assets/icon/course/user.png')"
        />
      </v-flex>
      <v-flex xs10 class="pl-5">
        <div class="font-16 font-w-600">จำนวนขั้นต่ำ</div>
        <div class="font-16">{{ model.number_minimum }} คน</div>
      </v-flex>
    </v-layout>

    <v-divider class="my-3" />

    <v-layout align-center>
      <v-flex shrink>
        <v-img
          aspect-ratio="1"
          width="30"
          :src="require('@/assets/icon/course/user.png')"
        />
      </v-flex>
      <v-flex xs10 class="pl-5">
        <div class="font-16 font-w-600">จำนวนผู้ประสงค์เข้าร่วมรอบถัดไป</div>
        <div class="font-16">{{ model.user_course_remind_count }} คน</div>
      </v-flex>
    </v-layout>

    <v-divider class="my-3" />

    <div class="text-center">
      <div
        v-if="model.user_course.length > 0"
        class="`mx-3 btn-gray font-16"
        large
        rounded
      >
        <span class="font-w-500">
          {{ participantStatus(model) }}
        </span>
      </div>
      <div
        v-else
        @click="register(model.id, participantStatus(model))"
        class="`mx-3 btn-green-gradient cursor-pointer font-20"
        large
        rounded
      >
        <span class="font-w-500">
          {{ participantStatus(model) }}
        </span>
      </div>

      <!-- <v-btn
          :disabled="model.user_course.length > 0 || !user || !user.status_approve_user"
          @click="register(model.id)"
          :class="`mx-3 font-20 ${
            model.user_course.length > 0 ? 'bg-footer' : 'btn-green-gradient'
          } `"
          large
          rounded
        >
          {{ participantStatus(model) }}
        </v-btn> -->
    </div>
  </v-card>
</template>

<script>
import moment from "moment";
import Loading from "../Loading.vue";
export default {
  props: ["model"],
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    access_token() {
      return this.$store.state.auth.access_token;
    },
  },
  methods: {
    changeFormat(dateTime, oldFormat, newFormat) {
      return moment(dateTime, oldFormat).format(newFormat);
    },
    calculateRemindUser(attend, full) {
      return full - attend;
    },
    async register(id, txt) {
      if (this.access_token) {
        if (txt.includes('เข้าร่วมหลักสูตร')) {
          this.$swal({
            title: "คุณแน่ใจไหม?",
            text: "ว่าสามารถเข้าร่วมอบรมตามวันและเวลาดังกล่าวได้"+
            "เมื่อยืนยันแล้วหากท่านไม่เข้าร่วมโดยไม่ได้แจ้งยกเลิกล่วงหน้า"+
            "อาจมีผลต่อการสมัครในครั้งต่อไป",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4090FF",
            cancelButtonColor: "#BCC4CB",
            confirmButtonText: "ใช่",
            cancelButtonText: "ไม่ใช่",
          }).then(async (result) => {
              if (result.isConfirmed) {
                this.isLoading = true;
                await this.$store
                    .dispatch("Course/onRegister", {
                      course_id: id,
                      user_id: this.user ? this.user.id : "",
                    })
                    .then((response) => {
                      if (response === 401) {
                        this.$swal({
                          title: `ผิดพลาด`,
                          text: "ลงชื่อเข้าใช้งานก่อนลงทะเบียน",
                          icon: "error",
                          confirmButtonText: "ตกลง",
                        }).then(() => {
                          this.$router.push(`/login`);
                        });
                      } else {
                        if (txt.includes('เข้าร่วมหลักสูตร')){
                          this.$swal({
                            title: "ทำรายการสำเร็จ",
                            text: "ระบบได้ยืนยันการสมัครอบรมของท่านแล้ว"+
                                "ก่อนอบรม 1 วัน ระบบจะจัดส่งลิงค์ไปยังเมลของท่าน หากไม่ได้รับ กรุณาเช็คที่อีเมลขยะ หรือเมลสแปม"+
                                " (junk mail or spam)",
                            icon: "success",
                            confirmButtonText: "ตกลง",
                          }).then((res) => {
                            this.$router.go();
                          });
                        }else{
                          this.$swal({
                            title: "ทำรายการสำเร็จ",
                            text: "คุณได้" + txt + "เรียบร้อยแล้ว",
                            icon: "success",
                            confirmButtonText: "ตกลง",
                          }).then((res) => {
                            this.$router.go();
                          });
                        }

                      }
                    });
                this.isLoading = false;
              }
            });
        }else{
          this.$swal({
            title: "คุณแน่ใจไหม?",
            text: "คุณต้องการที่จะ" + txt + "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4090FF",
            cancelButtonColor: "#BCC4CB",
            confirmButtonText: "ใช่",
            cancelButtonText: "ไม่ใช่",
          }).then(async (result) => {
              if (result.isConfirmed) {
                this.isLoading = true;
                await this.$store
                    .dispatch("Course/onRegister", {
                      course_id: id,
                      user_id: this.user ? this.user.id : "",
                    })
                    .then((response) => {
                      if (response === 401) {
                        this.$swal({
                          title: `ผิดพลาด`,
                          text: "ลงชื่อเข้าใช้งานก่อนลงทะเบียน",
                          icon: "error",
                          confirmButtonText: "ตกลง",
                        }).then(() => {
                          this.$router.push(`/login`);
                        });
                      } else {
                        if (txt.includes('เข้าร่วมหลักสูตร')){
                          this.$swal({
                            title: "ทำรายการสำเร็จ",
                            text: "ระบบได้ยืนยันการสมัครอบรมของท่านแล้ว"+
                                "ก่อนอบรม 1 วัน ระบบจะจัดส่งลิงค์ไปยังเมลของท่าน หากไม่ได้รับ กรุณาเช็คที่อีเมลขยะ หรือเมลสแปม"+
                                " (junk mail or spam)",
                            icon: "success",
                            confirmButtonText: "ตกลง",
                          }).then((res) => {
                            this.$router.go();
                          });
                        }else{
                          this.$swal({
                            title: "ทำรายการสำเร็จ",
                            text: "คุณได้" + txt + "เรียบร้อยแล้ว",
                            icon: "success",
                            confirmButtonText: "ตกลง",
                          }).then((res) => {
                            this.$router.go();
                          });
                        }

                      }
                    });
                this.isLoading = false;
              }
            });
          }
      } else {
        this.$swal({
          title: "ไม่สามารถทำรายการได้",
          text: "กรุณาเข้าสู่ระบบก่อนทำรายการ",
          icon: "warning",
          confirmButtonText: "ตกลง",
        }).then((res) => {
          this.$router.push("/login");
        });
      }
    },
    participantStatus(model) {
      if (!this.access_token) {
        return "เข้าสู่ระบบก่อนสมัครอบรม";
      } else {
        const timeNow = moment();
        var endTime = moment(
          model.end_date_recruit + "23:59:59",
          "YYYY-MM-DD HH:mm:ss"
        );

        var status = "";

        // กรณีถ้า user ยังไม่ได้เข้าร่วม และต้องการที่จะเข้าร่วม แต่เลยเวลาเข้าร่วมหรือคนเต็มไปแล้ว
        if (
          !model.user &&
          (model.user_course_normal_count >= model.number_user ||
            timeNow.isAfter(endTime))
        ) {
          status = "ประสงค์เข้าร่วมหลักสูตรรอบถัดไป";
        }

        // กรณีถ้า user ยังไม่ได้เข้าร่วม ต้องการที่จะเข้าร่วม และเวลาเข้าร่วมยังไม่จบหรือคนยังไม่เต็ม
        else if (
          !model.user &&
          (model.user_course_normal_count < model.number_user ||
            !timeNow.isAfter(endTime))
        ) {
          status = "เข้าร่วมหลักสูตร";
        }

        // กรณีถ้า user กดประสงค์เข้าร่วมหลักสูตรรอบถัดไปแล้ว
        else if (model.user && model.user.participant_type === 1) {
          return "ประสงค์เข้าร่วมหลักสูตรรอบถัดไปแล้ว";
        }

        // กรณีถ้า user กดเข้าร่วมหลักสูตรแล้ว
        else {
          status = "เข้าร่วมหลักสูตรแล้ว";
        }

        return status;
      }
    },
  },
};
</script>

<style>
.cus-padding-col {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.btn-gray {
  background-color: #e9e9e9;
  color: #a2a2a2;
  border-radius: 30px;
  padding: 5px 10px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
